'use client';

import { memo as Memo } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Style
import Custom404Style from 'src/styles/pages/Custom404Style';

//* Helpers
import { config } from '@/helpers';

//* Components
import Page from '@/components/common/Page';
import Container from '@/components/common/Container';
import Image from '@/components/common/Image';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';

const Custom404 = Memo(({ translate }) => {
	return (
		<Page className={`error-404`}>
			<Container>
				<Custom404Style>
					<Image
						className={'rocket'}
						src={'/images/404-rocket.png'}
						alt={'404 rocket'}
					/>
					<Text
						className={'h1 font-futura-semibold main-title'}
						text={'404'}
					/>
					<Text className={'h1 font-futura-medium text-center'}>
						{translate('oops')} <span className='font-futura-light'>{translate('thatPage')}</span> {translate('cantFound')}
					</Text>
					<Text
						className={'h5 font-futura-regular description text-center'}
						text={'errorDescription'}
					/>
					<Button
						url={config.routes.shop.path}
						size={'medium'}
						text={'goToThePantry'}
					/>
				</Custom404Style>
			</Container>
		</Page>
	);
});

export default withLanguageContext(Custom404, ['translate']);
