import styled from 'styled-components'

const Custom404Style = styled.div`
	--customPageMarginTop: var(--sp10x);
	--mainTitleMarginTopBottom: var(--sp4x) 0 var(--sp1x) 0;
	--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp4x) 0;
	--rocketWidth: 9.6%;
	--rocketPaddingTop: 15.6%;

	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: var(--customPageMarginTop);

	.rocket {
		width: var(--rocketWidth);
		padding-top: var(--rocketPaddingTop);
	}

	.main-title {
		margin: var(--mainTitleMarginTopBottom);
	}

	.description {
		margin: var(--descriptionMarginTopBottom);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--customPageMarginTop: var(--sp9x);
		--mainTitleMarginTopBottom: var(--sp4x) 0 var(--sp1x) 0;
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp4x) 0;
		--rocketWidth: 10.9%;
		--rocketPaddingTop: 17.9%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--customPageMarginTop: var(--sp9x);
		--mainTitleMarginTopBottom: var(--sp4x) 0 var(--sp1x) 0;
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp4x) 0;
		--rocketWidth: 12.5%;
		--rocketPaddingTop: 20.4%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--customPageMarginTop: var(--sp8x);
		--mainTitleMarginTopBottom: var(--sp3x) 0 var(--sp1x) 0;
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp3x) 0;
		--rocketWidth: 13.6%;
		--rocketPaddingTop: 22.4%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--customPageMarginTop: var(--sp8x);
		--mainTitleMarginTopBottom: var(--sp3x) 0 var(--sp1x) 0;
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp3x) 0;
		--rocketWidth: 18.2%;
		--rocketPaddingTop: 29.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--customPageMarginTop: var(--sp7x);
		--mainTitleMarginTopBottom: var(--sp2x) 0 var(--sp1x) 0;
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp2x) 0;
		--rocketWidth: 29.5%;
		--rocketPaddingTop: 48.1%;
	}
`

export default Custom404Style
